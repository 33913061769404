/* You can add global styles to this file, and also import other style files */

@import './libs/assets/colors';
@import './libs/assets/sizes';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import './libs/assets/base-theme';

.margin-0 {
  margin: 0 !important;
}

hr {
  opacity: 0.5;
  margin-bottom: 30px;
}

.preview-item {
  margin-bottom: 30px;

  label {
    font-weight: 700;
    color: $primary;
    margin-bottom: 6px;
    display: block;
  }

  h1 {
    color: $blue-gray;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 2px;
  }
}

.static-table {
  border: 1px solid $gray-light-2;
  border-collapse: collapse;

  thead {
    tr {
      background-color: $gray-light-2;
      border-bottom: 1px solid $gray-light-2;
      height: 36px;

      th {
        text-align: left;
        padding: 0 5px;
        font-weight: 400;
        color: $gray-dark-1;
      }
    }
  }

  tbody {
    tr {
      height: 30px;
      border-bottom: 1px solid $gray-light-3;

      td {
        padding: 0 5px;
        color: $gray-dark-1;
      }

      &.total {
        background-color: $gray-light-2;
        height: 36px;

        td {
          font-weight: 700;
        }
      }
    }
  }
}

.captain-app {
  .page-content {
    padding-bottom: 20px;
  }
}

.page-content {
  padding-bottom: 90px;

  .page-content {
    padding-bottom: 0;
  }
}

.primary-btn {
  border: 1px solid $primary !important;
  padding: 2px 30px !important;
  color: $primary-darken-10;
  background-color: $white;
  font-weight: 300;
  box-shadow: none !important;

  .mat-button-wrapper {
    white-space: break-spaces;
  }

  &.filled-btn {
    background-color: $primary;
    color: $white;
  }

  @media screen and (max-width: $smMax) {
    padding: 0 10px !important;
  }
  &[disabled] {
    opacity: 0.4;
  }
}

.primary-btn,
.mat-raised-button,
.mat-button,
.mat-stroked-button,
.mat-flat-button {
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 0.15s ease !important;

  &:not(:disabled):hover {
    transform: scale(1.05);
  }
}

.dark-theme {
  .primary-btn {
    color: $table-font-color;
  }
}

.warn-btn {
  border: 1px solid $error !important;
  height: 40px;
  padding: 0 30px !important;
  color: $error-darken;
  font-weight: 300;
  box-shadow: none !important;
  &.filled-btn {
    border: 1px solid $error-darken !important;
    background-color: $error;
    color: $text-on-primary;
  }
}

.filters-wrapper {
  margin-bottom: 20px;
  border: 1px solid $gray-light-3;
  padding: 20px 50px;
  background-color: $white;
  font-size: 12px;

  .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
    display: none;
  }
}

span.status-badge {
  padding: 5px 10px;
  color: #ffffff;
  border-radius: 14px;
  background: $success;
  text-transform: capitalize;
  font-weight: 500;

  &.unassigned,
  &.declined,
  &.error {
    background: $error;
  }

  &.assigned,
  &.submitted {
    background: $warn;
    color: #212529;

    &.charity-assigned {
      background: $success;
      color: $text-on-primary;
    }
  }

  &.canceled {
    background: #0261ad;
  }
}

.has-filters-flag {
  color: $primary;
  font-size: 12px;
  margin-left: 2px;
}

@media print {
  .logistics-report {
    .table-container,
    .card-wrapper {
      height: auto !important;
    }
  }
}

.table-container {
  overflow: overlay;
  border: 1px solid $gray-light-3;
  min-height: 300px;
  background-color: $gray-light-1;

  thead {
    th {
      padding: 4px 8px;
      font-size: 14px;
    }
  }

  tbody {
    tr {
      height: 45px;

      &:not(.expandable-detail-row):hover {
        background-color: rgba(31, 115, 183, 0.08);
      }

      td {
        padding: 4px 8px;
      }
    }
  }
}

.card-wrapper .mat-tab-body-wrapper {
  &,
  .mat-tab-body.mat-tab-body-active {
    &,
    .mat-tab-body-content {
      overflow: visible !important;
      overflow-y: visible !important;
      overflow-x: visible !important;
    }
  }
}

.pac-container {
  z-index: 99999;
}
